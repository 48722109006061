import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { Auth } from './auth'
import useAuth from './use-auth'

const RequireAuth = ({ allowedRoles, ...auth }) => {
  // const { auth } = useAuth()
  const location = useLocation()

  return auth?.featureList?.find((role) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : auth?.user ? (
    <Navigate to='/unauthorized' state={{ from: '/' }} replace />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  )
}

export default RequireAuth
